import { BACKEND } from "../variables.js";
import axios from "axios";
const SEND_MESSAGE = BACKEND + "/messages/sendMessage";
const MARK_AS_READ = BACKEND + "/messages/markAsRead";
const DELETE_MESSAGE = BACKEND + "/messages/deleteMessage";
const CREATE_CHAT = BACKEND + "/chat/createChat";
const GET_MY_CHATS = BACKEND + "/chat/getMyChats";
const GET_CHAT_BY_ID = BACKEND + "/chat/getChatById";
const DELETE_CHAT = BACKEND + "/chat/deleteChat";

export default {
  state: {
    wings: [],
  },
  getters: {},
  mutations: {},
  actions: {
    ["SEND_MESSAGE"](state, payload) {
      return axios({
        method: "POST",
        url: SEND_MESSAGE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["MARK_AS_READ"](state, payload) {
      return axios({
        method: "POST",
        url: MARK_AS_READ,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_MESSAGE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_MESSAGE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_CHAT"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_CHAT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_CHATS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_MY_CHATS,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_CHAT_BY_ID"](state, payload) {
      return axios({
        method: "POST",
        url: GET_CHAT_BY_ID,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_CHAT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_CHAT,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
