<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span
          >Rooms / Units <small>({{ rooms.length }})</small></span
        ><a href="" class="my-btn cw glow wd" @click.prevent="startAdd = true">
          Add Room
        </a>
      </h1>
      <div class="my-table">
        <form class="table-filters">
          <!-- Wing -->
          <fieldset>
            <legend>Wing</legend>
            <div class="form-group">
              <select
                id="wing"
                class="form-control"
                v-model="searchQuery.wing_id"
              >
                <option value="all">All</option>
                <option
                  v-for="item in wings"
                  :key="item.wing_id"
                  :value="item.wing_id"
                >
                  {{ item.wing_name }}
                </option>
              </select>
            </div>
          </fieldset>
          <!-- Floor -->
          <fieldset>
            <legend>Floor</legend>
            <div class="form-group">
              <select
                id="floor"
                class="form-control"
                v-model="searchQuery.floor_id"
              >
                <option value="all">All</option>
                <option
                  v-for="item in floors"
                  :key="item.floor_id"
                  :value="item.floor_id"
                >
                  {{ item.floor_name }}
                </option>
              </select>
            </div>
          </fieldset>
          <!-- Class -->
          <fieldset>
            <legend>Class</legend>
            <div class="form-group">
              <select
                id="class"
                class="form-control"
                v-model="searchQuery.room_category_id"
              >
                <option value="all">All</option>
                <option
                  v-for="item in roomClasses"
                  :key="item.room_category_id"
                  :value="item.room_category_id"
                >
                  {{ item.room_category_name }}
                </option>
              </select>
            </div>
          </fieldset>
          <fieldset>
            <legend>Status</legend>
            <div class="form-group">
              <select
                id="approvedBy"
                class="form-control"
                v-model="searchQuery.status"
              >
                <option value="all">All</option>
                <option value="Available">Available</option>
                <option value="Unavailable">Unavailable</option>
                <option value="Occupied">Occupied</option>
                <option value="In Repair">In Repair</option>
              </select>
            </div>
          </fieldset>
          <!-- Search -->
          <fieldset>
            <legend>Search</legend>
            <div class="form-group">
              <input
                type="text"
                id="name"
                class="form-control"
                placeholder="Search"
                v-model="searchQuery.search"
              />
            </div>
          </fieldset>
          <!-- Filter -->
          <div class="form-group">
            <button
              class="my-btn cw wd glow"
              type="submit"
              @click.prevent="getItems()"
            >
              Filter
            </button>
          </div>
        </form>
        <RoomCards
          :rooms="rooms"
          class="rooms-section"
          @editRoom="
            startEdit = true;
            selectedItem = $event;
          "
          @deleteRoom="
            startDelete = true;
            selectedItem = $event;
          "
        />
        <div class="pagination" v-if="pagesNumber > 1">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Modal
        :title="'Delete Room'"
        :size="'sm'"
        v-model:open="startDelete"
        @closeModel="startDelete = false"
        centered
        :okText="'Delete'"
        @ok="deleteItem()"
        width="400px"
      >
        <p>Are you sure you want to delete this Room?</p>
      </Modal>
      <Modal
        :title="'Add Room'"
        :size="'lg'"
        v-model:open="startAdd"
        centered
        :okText="'Save'"
        @ok="createItem()"
        width="800px"
      >
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">Room Name</label>
            <input
              type="text"
              id="name"
              v-model="newItem.room_name"
              class="form-control"
              placeholder="Room Name"
            />
          </div>
          <div class="input-group">
            <label for="name">Room number</label>
            <input
              type="text"
              id="name"
              v-model="newItem.room_number"
              class="form-control"
              placeholder="Room Number"
            />
          </div>
          <div class="input-group">
            <label for="status">Status</label>
            <select id="status" v-model="newItem.status" class="form-control">
              <option value="Available">Available</option>
              <option value="Unavailable">Unavailable</option>
              <option value="Occupied">Occupied</option>
              <option value="In Repair">In Repair</option>
            </select>
          </div>
        </div>
        <div class="form-group group-3">
          <div class="input-group">
            <label for="wing">Wing</label>
            <select
              id="wing"
              v-model="newItem.wing_id"
              class="form-control"
              @change="$getFloorsByWing(newItem.wing_id)"
            >
              <option value="">Select wing</option>
              <option
                v-for="item in wings"
                :key="item.wing_id"
                :value="item.wing_id"
              >
                {{ item.wing_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="floor">Floor</label>
            <select id="floor" v-model="newItem.floor_id" class="form-control">
              <option value="">Select floor</option>
              <option
                v-for="item in floors"
                :key="item.floor_id"
                :value="item.floor_id"
              >
                {{ item.floor_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="class">Class</label>
            <select
              id="class"
              v-model="newItem.room_category_id"
              class="form-control"
              @change="
                getRoomEquipmentsByClass('add', newItem.room_category_id)
              "
            >
              <option value="">Select class</option>
              <option
                v-for="item in roomClasses"
                :key="item.room_category_id"
                :value="item.room_category_id"
              >
                {{ item.room_category_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Room Price <span>(number only)</span></label>
            <input
              type="number"
              id="name"
              v-model="newItem.room_price"
              class="form-control"
              placeholder="Room Price"
            />
          </div>
          <div class="input-group">
            <label for="name">Payment frequency</label>
            <select id="name" v-model="newItem.frequency" class="form-control">
              <option value="">Select frequency</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="companyEquipments">Room Equipments</label>
          <a-select
            v-model:value="newItem.equipments"
            mode="tags"
            style="width: 100%"
            :token-separators="[',']"
            placeholder="Room Equipments"
          >
            <a-select-option
              v-for="item in roomEquipments"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div>
      </Modal>
      <Modal
        :title="'Edit Room'"
        :size="'sm'"
        v-model:open="startEdit"
        @closeModel="startEdit = false"
        centered
        :okText="'Save'"
        @ok="updateItem()"
        width="800px"
      >
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">Room Name</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.room_name"
              class="form-control"
              placeholder="Room Name"
            />
          </div>
          <div class="input-group">
            <label for="name">Room number</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.room_number"
              class="form-control"
              placeholder="Room Number"
            />
          </div>
          <div class="input-group">
            <label for="status">Status</label>
            <select
              id="status"
              v-model="selectedItem.status"
              class="form-control"
            >
              <option value="Available">Available</option>
              <option value="Unavailable">Unavailable</option>
              <option value="Occupied">Occupied</option>
              <option value="In Repair">In Repair</option>
            </select>
          </div>
        </div>
        <div class="form-group group-3">
          <div class="input-group">
            <label for="wing">Wing</label>
            <select
              id="wing"
              v-model="selectedItem.wing_id"
              class="form-control"
              @change="$getFloorsByWing(selectedItem.wing_id)"
            >
              <option value="">Select wing</option>
              <option
                v-for="item in wings"
                :key="item.wing_id"
                :value="item.wing_id"
              >
                {{ item.wing_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="floor">Floor</label>
            <select
              id="floor"
              v-model="selectedItem.floor_id"
              class="form-control"
            >
              <option value="">Select floor</option>
              <option
                v-for="item in floors"
                :key="item.floor_id"
                :value="item.floor_id"
              >
                {{ item.floor_name }}
              </option>
            </select>
          </div>
          <div class="input-group">
            <label for="class">Class</label>
            <select
              id="class"
              v-model="selectedItem.room_category_id"
              class="form-control"
              @change="
                getRoomEquipmentsByClass(
                  'update',
                  selectedItem.room_category_id
                )
              "
            >
              <option value="">Select class</option>
              <option
                v-for="item in roomClasses"
                :key="item.room_category_id"
                :value="item.room_category_id"
              >
                {{ item.room_category_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">Room Price <span>(number only)</span></label>
            <input
              type="number"
              id="name"
              v-model="selectedItem.room_price"
              class="form-control"
              placeholder="Room Price"
            />
          </div>
          <div class="input-group">
            <label for="name">Payment frequency</label>
            <select
              id="name"
              v-model="selectedItem.frequency"
              class="form-control"
            >
              <option value="">Select frequency</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label for="companyEquipments">Room Equipments</label>
          <a-select
            v-model:value="selectedItem.equipments"
            mode="tags"
            style="width: 100%"
            :token-separators="[',']"
            placeholder="Room Equipments"
          >
            <a-select-option
              v-for="item in roomEquipments"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </div>
      </Modal>
    </div>
  </div>
</template>
  
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Tag, Modal } from "ant-design-vue";
import RoomCards from "./RoomCards.vue";
export default {
  components: {
    // Tag,
    Modal,
    RoomCards,
  },
  data() {
    return {
      rooms: [],
      newItem: {
        room_name: "",
        room_number: "",
        wing_id: "",
        floor_id: "",
        room_category_id: "",
        room_price: "",
        frequency: "Monthly",
        equipments: [],
        status: "Available",
        organization_id: this.$getOrganizationId(),
        house_id: this.$getHouseId(),
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 18,
      pagesNumber: 0,
      currentPage: 1,
      roomClasses: [],
      floors: [],
      wings: [],
      admins: [],
      searchQuery: {
        wing_id: "all",
        floor_id: "all",
        room_category_id: "all",
        status: "all",
        search: "",
      },
      roomEquipments: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ROOMS", {
          token: this.$getAdminToken(),
          data: {
            paginate: this.itemsPerpage,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            house_id: this.$getHouseId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.rooms = res.data.data.data;
              this.allItemsCount = res.data.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (
        this.newItem.room_name &&
        this.newItem.room_number &&
        this.newItem.wing_id &&
        this.newItem.floor_id &&
        this.newItem.room_category_id &&
        this.newItem.room_price
      ) {
        this.newItem.organization_id = this.$getOrganizationId();
        this.newItem.house_id = this.$getHouseId();
        this.$startLoader();
        this.$store
          .dispatch("CREATE_ROOM", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (
        this.selectedItem.room_name &&
        this.selectedItem.room_number &&
        this.selectedItem.wing_id &&
        this.selectedItem.floor_id &&
        this.selectedItem.room_category_id &&
        this.selectedItem.room_price
      ) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ROOM", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ROOM", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getRoomEquipmentsByClass(type, id) {
      if (id == "") {
        this.roomEquipments = [];
        this.newItem.equipments = [];
        return;
      }
      let selectedClass = this.roomClasses.find(
        (item) => item.room_category_id == id
      );
      this.roomEquipments = [...selectedClass.equipments];
      if (type == "update") {
        this.selectedItem.equipments = [...selectedClass.equipments];
      } else {
        this.newItem.equipments = [...selectedClass.equipments];
      }
    },
  },
  mounted() {
    this.getItems();
    this.$getRoomClasses();
    this.$getFloors();
    this.$getWings();
    this.$getAdminsByOrg();
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
      }
    }
  }
}

table {
  margin-top: 2rem;
}
.table-filters {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  fieldset {
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    legend {
      font-size: 0.7rem;
      font-weight: bold;
      margin: 0 10px;
      color: #666;
      font-weight: 400;
      padding: 0 10px;
    }
    .form-group {
      padding: 0 !important;
      margin: 0 !important;
      select,
      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        border-radius: 5px;
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }
  }
  button {
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: $primary_color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      background-color: $secondary_color;
    }
  }
}
.rooms-section {
  min-height: 30vh;
}
</style>