import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_EMPLOYEES = BACKEND + "/admin/getAdminsByHouseId";
const CREATE_EMPLOYEE = BACKEND + "/admin/createAdmin";
const UPDATE_EMPLOYEE = BACKEND + "/admin/updateAdmin";
const DELETE_EMPLOYEE = BACKEND + "/admin/deleteAdmin";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_EMPLOYEES"](state, payload) {
      return axios({
        method: "POST",
        url: GET_EMPLOYEES + `?page=${payload.data.page}`,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["CREATE_EMPLOYEE"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_EMPLOYEE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_EMPLOYEE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_EMPLOYEE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_EMPLOYEE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_EMPLOYEE,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
