<template>
  <div id="RoomCards" v-if="rooms.length > 0">
    <div
      class="card"
      v-for="room in rooms"
      :key="room.id"
      @click="$emit('roomSelected', room)"
    >
      <div class="card-body">
        <h5 class="card-title">{{ room.room_name }}</h5>
        <div class="counts-section">
          <div class="counts">
            <div class="count-item">
              <label for="floors">Number</label>
              <p class="floors">{{ room.room_number }}</p>
            </div>
            <div class="count-item">
              <label for="rooms">Price</label>
              <p class="rooms">{{ $comma(room.room_price) }}</p>
            </div>
            <div class="count-item">
              <label for="rooms">Tenant</label>
              <p
                class="rooms"
                v-if="room.tenant && room.tenant.tenant_type == 'company'"
              >
                {{ room.tenant.company_name }}
              </p>
              <p
                class="rooms"
                v-if="room.tenant && room.tenant.tenant_type != 'company'"
              >
                {{ room.tenant.tenant_name }}
              </p>
              <p class="rooms" v-if="!room.tenant"><i>-</i></p>
            </div>
            <div class="count-item">
              <label for="rooms">Status</label>
              <p class="rooms">
                <Tag color="#87d068" v-if="room.status == 'Available'">{{
                  room.status
                }}</Tag>
                <Tag color="#db4d4d" v-if="room.status == 'Unavailable'">{{
                  room.status
                }}</Tag>
                <Tag color="#555" v-if="room.status == 'Occupied'">{{
                  room.status
                }}</Tag>
                <Tag color="#ab5839" v-if="room.status == 'In Repair'">{{
                  room.status
                }}</Tag>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-options">
        <!-- <div class="option" @click="$emit('roomSelected', room)">View</div> -->
        <div class="option" @click="$emit('editRoom', room)">Edit</div>
        <div class="option delete" @click="$emit('deleteRoom', room)">
          Delete
        </div>
      </div>
    </div>
    <!-- <div class="add-card">
      <div class="card-content">
        <i class="fas fa-edit"></i>
        <p>Add room</p>
      </div>
    </div> -->
  </div>
</template>
    
<script>
import { toast } from "vue3-toastify";
import { Tag, Modal } from "ant-design-vue";
export default {
  components: {
    Tag,
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {},
};
</script>
    
  <style scoped lang="scss">
@import "@/assets/main.scss";
#RoomCards {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  .card {
    cursor: pointer;
    width: 200px;
    height: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
    .card-body {
      border-radius: 10px;
      overflow: hidden;
      background: white;
      .card-title {
        font-size: 1.1rem;
        font-weight: 400;
        margin-bottom: 0px;
        text-align: center;
        padding: 1rem 10px;
        background: $primary_color;
        border-radius: 0px 0px 30px 0px;
        color: white;
      }
      .counts-section {
        background: $primary_color;
        .counts {
          display: flex;
          flex-direction: column;
          background: white;
          padding: 20px 10px 10px 10px;
          border-radius: 30px 0px 0px 0px;
          gap: 10px;
          .count-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #00000017;
            padding: 0 10px;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            label {
              font-size: 0.8rem;
              color: #333;
            }
            p {
              font-size: 0.8rem;
              font-weight: 600;
            }
          }
        }
      }
    }
    .card-options {
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 10px;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 10px;
      display: none;
      transition: display 0.3s ease;
      // background blur effect
      backdrop-filter: blur(2px);
      background: rgba(255, 255, 255, 0.5);

      .option {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 5px;
        background: $primary_color;
        color: white;
        width: 100px;
        font-size: 0.8rem;
        &:hover {
          background: darken($primary_color, 10%);
        }
        &.delete {
          background: #db4d4d;
          &:hover {
            background: darken(#db4d4d, 10%);
          }
        }
      }
    }

    &:hover {
      .card-options {
        display: flex;
      }
    }
  }

  .card::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: -2px;
    bottom: -2px;
    border-radius: inherit;
    background: linear-gradient(45deg, #c06c6c, #2150e7, #8b4dff, #54d68f);
    background-size: 300% 300%;
    animation: gradientGlow 3s ease infinite;
    opacity: 0;
    transition: opacity 1s ease;
    z-index: -1;
  }

  .card:hover::before {
    opacity: 1;
  }

  @keyframes gradientGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    80% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .add-card {
    cursor: pointer;
    width: 200px;
    height: 149px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: white;
    color: $primary_color;
    font-size: 0.9rem;
    position: relative;
    z-index: 1;
    .card-content {
      gap: 10px;
      background: white;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      i {
        margin-right: 10px;
        font-size: 1.1rem;
      }
      p {
        font-weight: 400;
        color: #333;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: -2px;
      bottom: -2px;
      border-radius: inherit;
      background: linear-gradient(45deg, #c06c6c, #2150e7, #8b4dff, #54d68f);
      background-size: 300% 300%;
      animation: gradientGlow 3s ease infinite;
      opacity: 0;
      transition: opacity 1s ease;
      z-index: -1;
    }
    &:hover::before {
      opacity: 1;
    }
  }
}
</style>