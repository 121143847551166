<template>
  <div id="TenantCard">
    <div
      class="card"
      v-for="tenant in tenants"
      :key="tenant.id"
      @click="$emit('tenantSelected', tenant)"
    >
      <div class="card-body">
        <div class="card-header">
          <div class="card-header-left">
            <div class="tenant-img">
              <img :src="$upload(tenant.tenant_image)" alt="tenant" />
            </div>
            <div class="name-data">
              <h5 v-if="tenant.tenant_type == 'company'">
                {{ tenant.company_name }}
              </h5>
              <h5 v-else>{{ tenant.tenant_name }}</h5>
              <p>{{ tenant.tenant_email }}</p>
              <Tag color="#87d068" v-if="tenant.status == 'active'">Active</Tag>
              <Tag color="#db4d4d" v-if="tenant.status == 'inactive'"
                >Inactive</Tag
              >
            </div>
          </div>
          <div class="card-header-right">
            <div class="quick-actions">
              <button class="edit" @click="$emit('editTenant', tenant)">
                <i class="fas fa-pencil-alt"></i>
              </button>
              <button class="delete" @click="$emit('deleteTenant', tenant)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="other-info">
            <p><b>Contact Number : </b> {{ tenant.tenant_phone }}</p>
            <p v-if="tenant.tenant_type == 'company'">
              <b>Company : </b> {{ tenant.company_name }}
            </p>
            <p v-else><b>Company : </b> -</p>
            <p v-if="tenant.tenant_type == 'company'">
              <b>Company Email : </b> {{ tenant.company_email }}
            </p>
            <p v-else><b>Company Email : </b> -</p>
            <p v-if="tenant.tenant_type == 'company'">
              <b>Company Phone : </b> {{ tenant.company_phone }}
            </p>
            <p v-else><b>Company Phone : </b> -</p>
            <p><b>Wing : </b> {{ tenant.wing.wing_name }}</p>
            <div class="subscriptions">
              <p>
                <b>Subscriptions: </b>
                <span v-if="tenant.subscriptions.length == 0"
                  >No subscriptions</span
                >
                <span
                  v-else
                  v-for="(subscription, index) in tenant.subscriptions"
                  :key="subscription.id"
                >
                  {{ subscription.room.room_number
                  }}<span v-if="index < tenant.subscriptions.length - 1"
                    >,</span
                  >
                  &nbsp;
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-primary" @click="$emit('openChat', tenant)">
            Open Chat
          </button>
          <button class="btn btn-default" @click="$emit('viewTenant', tenant)">
            View full profile
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag } from "ant-design-vue";
export default {
  props: {
    tenants: {
      type: Array,
      required: true,
    },
  },
  components: {
    Tag,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/main.scss";
// grid layout
#TenantCard {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1rem;
  .card {
    cursor: pointer;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px #0022440e;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    .card-body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .card-header {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1rem;
        border-bottom: 1px solid #e6edf5cc;
        .card-header-left {
          height: 100%;
          display: flex;
          gap: 1rem;
          .tenant-img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            overflow: hidden;
            background: #f4f4f4;
            padding: 3px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .name-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 0;
            h5 {
              font-size: 1rem;
              font-weight: 600;
              color: $primary-color;
            }
            p {
              font-size: 0.8rem;
              color: #687d92;
              font-weight: 400;
              margin-bottom: 0.15rem;
            }
          }
        }
        .card-header-right {
          width: 70%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          .quick-actions {
            display: flex;
            gap: 1rem;
            button {
              padding: 0.5rem 0.7rem;
              background-color: #f4f4f4;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              i {
                font-size: 1rem;
                color: #333;
              }
              &:hover {
                background-color: #e4e4e4;
              }
              &.edit {
                i {
                  color: $primary-color;
                }
              }
              &.delete {
                i {
                  color: #db4d4d;
                }
              }
            }
          }
        }
      }
      .card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 1rem;
        .other-info {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          p {
            font-size: 0.8rem;
            color: #687d92;
            font-weight: 400;
            b {
              color: #323a46;
              font-weight: 500;
            }
          }
          .subscriptions {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }
      }
      .card-footer {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 1rem;
        border-top: 1px solid #e6edf5cc;
        button {
          padding: 0.65rem 1rem;
          background-color: $primary-color;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          font-size: 0.8rem;
          font-weight: 400;
          &:hover {
            background-color: $secondary-color;
          }
          &.btn-default {
            background-color: #eef2f7;
            color: #333;
            &:hover {
              background-color: #d6dde6;
              color: #333;
            }
          }
        }
      }
    }
  }
}
</style>